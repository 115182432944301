import React from 'react'
// eslint-disable-next-line
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import { navigate } from 'gatsby'
import Button from 'components/CustomButtons/Button.jsx'
import './landingStyle.css'

class landingPage extends React.Component {
  render() {
    const data = this.props.data.markdownRemark

    return (
      <Layout>
        <HeaderSection>
          <Image
            src={require(`../../static/uploads/${data.frontmatter.image}`)}
          />
          <HeaderText>
            Hire the best {data.frontmatter.title} developers
          </HeaderText>
          <Button
            onClick={() => navigate('/hire')}
            round
            color="rose"
            size="lg"
          >
            <ButtonText>Hire Now</ButtonText>
          </Button>
        </HeaderSection>
        <Wrapper>
          <div
            className="landingStyle"
            dangerouslySetInnerHTML={{ __html: data.html }}
          />

          <ButtonWrap>
            <Button
              onClick={() => navigate('/hire')}
              round
              color="rose"
              size="lg"
              style={{ marginBottom: 20 }}
            >
              <ButtonText>Hire a {data.frontmatter.title} developer</ButtonText>
            </Button>
            <Button
              onClick={() => navigate('/apply')}
              round
              color="warning"
              size="lg"
            >
              <ButtonText>
                Apply as a {data.frontmatter.title} developer
              </ButtonText>
            </Button>
          </ButtonWrap>
        </Wrapper>
      </Layout>
    )
  }
}

export default landingPage

export const pageQuery = graphql`
  query LandingPageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        image
      }
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #232323;
  padding: 0 5%;
`
const HeaderSection = styled.div`
  height: 60vh;
  display: flex;
  background-color: #ff9000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 5%;
`

const HeaderText = styled.h1`
  font-size: 48px;
  padding: 5px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 36px;
  }
  @media (max-width: 650px) {
    font-size: 32px;
  }
`
const Image = styled.img`
  width: auto;
  height: 250px;
  overflow: hidden;
  border-radius: 50%;
  @media (max-width: 900px) {
    width: auto;
    height: 150px;
  }
`
const ButtonText = styled.p`
  font-size: 1.5vh;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  margin: 0;
  @media (max-width: 900px) {
    font-size: 1vh;
  }
`
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5% 5%;
`
